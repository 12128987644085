import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-04";
import EnterPriselocalizeProcess from "@containers/enterprise-localize/enterpriselocalize-process";
import LocalizeGrid from "@components/localize-grid/layout-1";
import TabsSection from "@components/bluetabs-section/layout-3";
import IntroArea from "@components/introarea/layout-2";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";

const EnterpriseLocalizationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Enterprise Localization"
        description=""
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
   
      <main className="site-wrapper-reveal">
        <PageHeader data={content["enterprise-page-header-section"]} />
        <FeatureGrid data={content["enterprise-localization-features"]} />
        <PartnerArea data={content["enterprise-localization-logo"]} />
        <IntroArea layout={2} data={content["enterprise-localization-intro"]} />
        {/* <Slider3D data={content["video-localization-applications"]} /> */}
        <LocalizeGrid data={content["enterprise-localize-solutions"]} />
        <EnterPriselocalizeProcess data={content["enterpriselocalization-process-body"]} />
        <TabsSection data={content["enterprise-localization-tool-body"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};
export const query = graphql`
  query EnterpriseLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "enterprise-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

EnterpriseLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default EnterpriseLocalizationPage;
